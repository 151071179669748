import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/appContext';
import Skeleton from 'react-loading-skeleton';
import { getAllTracks } from 'src/collections/tracks';
import { TrackType } from '../../modeltypes/tracks';
import { UnitType } from '../../modeltypes/unit';
import { getAllUnits } from '../../collections/units';
import { getAllGoals } from 'src/collections/goals';
import { GoalType } from 'src/modeltypes/goals';
import { getAllTags } from 'src/collections/tags';
import { TagType } from 'src/modeltypes/tags';
import { Pages } from '../../enums/pages';
import { getAllLessonAudioChunks } from 'src/collections/lessonAudioChunk';
import { LessonAudioChunkType } from 'src/modeltypes/lessonAudioChunk';
import { CSVDownload } from 'react-csv';
import { LessonType } from 'src/modeltypes/lesson';
import { getAllLessons } from 'src/collections/lesson';
import { getAllAudioNodes } from 'src/collections/audioNode';
import { AudioNodeType } from 'src/modeltypes/audioNode';
import { getAllChapters } from 'src/collections/chapter';
import { ChapterType } from 'src/modeltypes/chapter';
import { format } from 'date-fns';
import { SwitchOption } from 'src/utility/ScriptModel';
import SearchBar from '../SearchBar';

interface AudioNodeCsvData {
  audioUrl?: string | null;
  code?: string | null;
  doubleTap?: null | string;
  doubleTapTagId?: string | null;
  duration?: number | null;
  id?: string | null;
  mlAttribute?: string | null;
  nextSubsection?: null | string;
  nodeType?: string | null;
  silenceDuration?: number | null;
  singleTap?: null | string;
  singleTapTagId?: string | null;
  spokenText?: string | null;
  switchOptions?: SwitchOption[] | null; // What should I do with this?  If it is not in jason form
  title?: string | null;
  isIntroSeries?: boolean | null;
  tripleTap?: null | string;
  tripleTapTagId?: string | null;
  lessonDescription?: string | null;
  lessonDuration?: number | null;
  lessonId?: string | null;
  isMovementLesson?: boolean | null;
  lessonSubtitle?: string | null;
  lessonTags?: string[] | string | null; // What should I do with this?  If it is not in jason form
  trackTags?: string[] | string | null; // What should I do with this?  If it is not in jason form
  unitTags?: string[] | string | null; // What should I do with this?  If it is not in jason form
  lessonTitle?: string | null;
  lessonOrder?: number | null;
  unitTitle?: string | null;
  unitId?: string | null;
  unit?: number | null;
  trackId?: string | null;
  trackGoal?: string | null;
  chapterId?: string | null;
  chapterOrder?: number | null;
  trackTitle?: string | null;
  trackProductionStatus?: string | null;
  unitProductionStatus?: string | null;
  audioNodeType?: string | null;
}

const AudioNodeList = ({ showArchived }: { showArchived?: boolean }) => {
  console.log('showArchived', showArchived);
  const [tracks, setTracks] = useState<TrackType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();
  const [units, setUnits] = useState<UnitType[] | null>(null);
  const [goals, setGoals] = useState<GoalType[]>([]);
  const [audioChunks, setAudioChunks] = useState<LessonAudioChunkType[]>([]);
  const [audioLessons, setAudioLessons] = useState<LessonType[]>([]);
  const [audioNodes, setAudioNodes] = useState<AudioNodeType[]>([]);
  const [audioChapters, setAudioChapters] = useState<ChapterType[]>([]);
  const [tags, setTags] = useState<TagType[] | null>(null);
  const [audioNodeCsvData, setAudioNodeCsvData] = useState<AudioNodeCsvData[] | null>(null);

  if (
    tracks?.length &&
    units?.length &&
    audioChapters?.length &&
    tags?.length &&
    audioNodes?.length &&
    audioChunks?.length
  ) {
    console.log('ready');
  }

  const [downloadCSV, setDownloadCSV] = useState<boolean>(false);
  const getTags = async () => {
    const dbTags = await getAllTags();
    setTags(dbTags);
  };

  const getGoals = async () => {
    const dbGoals = await getAllGoals();
    setGoals(dbGoals);
  };

  const getTracks = async () => {
    const dbTracks = await getAllTracks();
    setTracks(dbTracks);
  };

  const getUnits = async () => {
    const dbUnits = await getAllUnits();
    setUnits(dbUnits);
  };

  const getAudioChunks = async () => {
    const dbChunks = await getAllLessonAudioChunks();
    setAudioChunks(dbChunks);
  };

  const getAudioLessons = async () => {
    const dbLessons = await getAllLessons();
    setAudioLessons(dbLessons);
  };

  const getAudioNodes = async () => {
    const audioNodes = await getAllAudioNodes();
    setAudioNodes(audioNodes);
  };

  const getChapters = async () => {
    const audioChapters = await getAllChapters();
    setAudioChapters(audioChapters);
  };

  useEffect(() => {
    getTags();
    getGoals();
    getAudioChunks();
    getAudioLessons();
    getAudioNodes();
    getChapters();
    getTracks();
    getUnits();
  }, []);

  const runJsonDownloadAudioNodes = () => {
    console.log('audioNodeCsvData?.length: ', audioNodeCsvData?.length);
    if (audioNodeCsvData && audioNodeCsvData.length > 0) {
      const today = new Date();
      const thisDate = format(today, `dd_MM_yyyy_HH_mm`);
      const jsonData = new Blob([JSON.stringify(audioNodeCsvData)], { type: 'application/json' });
      const jsonURL = URL.createObjectURL(jsonData);
      const link = document.createElement('a');
      link.href = jsonURL;
      link.download = `audio_nodes_${thisDate}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!audioNodeCsvData?.length) return [];
    return audioNodeCsvData.filter((item) => {
      const matchName = item.title && item.title.match(regex);
      const matchId = item.id && item.id.match(regex);
      const audioNodeType = item.audioNodeType && item.audioNodeType.match(regex);
      return matchName || matchId || audioNodeType;
    });
  }, [searchTerm, audioNodeCsvData]);

  useEffect(() => {
    const prepareTableData = async () => {
      const fAudioNodes = await getAllAudioNodes();
      const dbTracks = await getAllTracks();
      setTracks(dbTracks);
      const dbUnits = await getAllUnits();
      setUnits(dbUnits);
      const dbLessons = await getAllLessons();
      setAudioLessons(dbLessons);
      const dbChunks = await getAllLessonAudioChunks();
      setAudioChunks(dbChunks);
      const dbGoals = await getAllGoals();
      setGoals(dbGoals);

      const dbChapters = await getAllChapters();
      setAudioChapters(dbChapters);

      let lessonCount = 0;
      let chunkCount = 0;
      let orphanCount = 0;

      // Now we are going to reverse ENngineerin gthi
      const processAudioNodes = fAudioNodes.map((aN) => {
        let audioNodeType = null;

        const thisAudioLessonsChunk = dbChunks?.filter((audioChunk) => audioChunk?.audioNodes.includes(aN?.id));

        const thisAudioChapter = dbChapters?.filter((chapter) => chapter?.audioNodes?.includes(aN?.id));

        if (thisAudioLessonsChunk?.length > 1) {
          console.log('!!!!! THIS Audio Chunk is in more than one audio lesson chunk');
        }

        if (thisAudioChapter?.length > 1) {
          console.log('!!!!! THIS Audio Chapter Has more than 1 Item');
        }

        if (thisAudioLessonsChunk?.length) {
          audioNodeType = 'CHUNK';
          chunkCount += 1;
          const unitsIncludingThisChunk = dbUnits?.filter((unit) =>
            unit?.elements?.some((element) => element.id === thisAudioLessonsChunk?.[0]?.id),
          );
          const thisAudioChunk = thisAudioLessonsChunk?.[0];
          const thisUnit = unitsIncludingThisChunk?.[0];

          const thisElement = thisUnit?.elements?.find((uE) => uE.id === thisAudioChunk?.id);
          const thisElementOrder = thisElement?.order;

          const tracksIncludingThisChunk = dbTracks?.filter((track) =>
            track?.units?.some((unit) => unit.id === unitsIncludingThisChunk?.[0]?.id),
          );
          const track = tracksIncludingThisChunk?.[0];

          const thisTrackGoal = dbGoals.find((g: GoalType) => g?.id === track?.goal);

          const thisTrackUnit = track?.units?.find((tU) => tU?.id === thisUnit?.id);
          const thisUnitOrder = thisTrackUnit?.order;

          return {
            ...aN,
            audioNodeType,
            lessonDescription: thisAudioChunk?.description || null,
            lessonDuration: thisAudioChunk?.duration || null,
            lessonId: thisAudioChunk?.id || null,
            isMovementLesson: thisAudioChunk?.isMovementLesson || false,
            lessonSubtitle: thisAudioChunk?.subtitle || null,
            lessonTags: thisAudioChunk?.tags || null,
            lessonTitle: thisAudioChunk?.title || null,
            description: thisAudioChunk?.description || null,
            unitTitle: thisUnit?.title || null,
            unitId: thisUnit?.id || null,
            unitTags: thisUnit?.tags || null,
            unit: typeof thisUnitOrder === 'number' ? thisUnitOrder + 1 : null,
            trackId: track?.id || null,
            trackTags: track?.tags || null,
            trackGoal: track?.goal || null,
            trackTitle: track?.title || null,
            isDefaultTrack: track?.defaultTrack || false,
            isIntroSeries: track?.isIntroSeries || false,
            lessonOrder: typeof thisElementOrder === 'number' ? thisElementOrder + 1 : null,
            trackProductionStatus: track?.productionStatus || null,
            unitProductionStatus: thisUnit?.productionStatus || null,
            goalDescription: thisTrackGoal?.description || null,
            goalName: thisTrackGoal?.name || null,
            goalTags: thisTrackGoal?.tags || null,
          };
        }

        if (thisAudioChapter?.length) {
          const thisChapter = thisAudioChapter?.[0];

          const lessonsIncludingThisNode = audioLessons?.filter((aL) =>
            aL.chapters?.includes(thisAudioChapter?.[0]?.id),
          );
          // console.log("lessonsIncludingThisNode: ", lessonsIncludingThisNode);

          const thisAudioLesson = lessonsIncludingThisNode?.[0];
          const lessonChapterIds = thisAudioLesson?.chapters;
          const thisChaperOrder = lessonChapterIds?.indexOf(thisChapter?.id);

          // console.log("lessonsIncludingThisNode: thisChaperOrder", thisChaperOrder);
          console.log('lessonsIncludingThisNode: thisAudioLesson?.id', thisAudioLesson?.id);

          const unitsIncludingThisChunk = dbUnits?.filter((unit) =>
            unit?.elements?.some((element) => element?.id === thisAudioLesson?.id),
          );
          console.log('lessonsIncludingThisNode: unitsIncludingThisChunk', unitsIncludingThisChunk);

          const thisUnit = unitsIncludingThisChunk?.[0];
          // console.log("lessonsIncludingThisNode: thisUnit", thisUnit);

          const thisElement = thisUnit?.elements?.find((uE) => uE?.id === thisAudioLesson?.id);
          // console.log("lessonsIncludingThisNode: thisElement", thisElement);

          const thisElementOrder = thisElement?.order;

          const tracksIncludingThisChunk = dbTracks?.filter((track) =>
            track.units?.some((unit) => unit?.id === unitsIncludingThisChunk?.[0]?.id),
          );
          const track = tracksIncludingThisChunk?.[0];

          const thisTrackUnit = track?.units?.find((tU) => tU?.id === thisUnit?.id);
          const thisUnitOrder = thisTrackUnit?.order;

          const thisTrackGoal = goals.find((g: GoalType) => g?.id === track?.goal);

          if (!thisElementOrder || !thisTrackUnit) {
            console.log('thisChapter: ', thisChapter);
            console.log('aN: ', aN);
          }

          audioNodeType = 'LESSON';
          lessonCount += 1;

          return {
            ...aN,
            audioNodeType,
            lessonDescription: thisAudioLesson?.description || null,
            lessonDuration: thisAudioLesson?.duration || null,
            lessonId: thisAudioLesson?.id || null,
            isMovementLesson: false,
            lessonSubtitle: thisAudioLesson?.subtitle || null,
            lessonTags: thisAudioLesson?.tags || null,
            lessonTitle: thisAudioLesson?.title || null,
            description: thisAudioLesson?.description || null,
            unitTitle: thisUnit?.title || null,
            unitId: thisUnit?.id || null,
            unit: typeof thisUnitOrder === 'number' ? thisUnitOrder + 1 : null,
            unitTags: thisUnit?.tags || null,
            trackId: track?.id || null,
            trackTitle: track?.title || null,
            trackTags: track?.tags || null,
            isDefaultTrack: track?.defaultTrack || false,
            isIntroSeries: track?.isIntroSeries || false,
            lessonOrder: typeof thisElementOrder === 'number' ? thisElementOrder + 1 : null,
            chapterId: thisChapter?.id,
            chapterOrder: (thisChaperOrder || 0) + 1 || null,
            trackProductionStatus: track?.productionStatus || null,
            unitProductionStatus: thisUnit?.productionStatus || null,
            trackGoal: track?.goal || null,
            goalDescription: thisTrackGoal?.description || null,
            goalName: thisTrackGoal?.name || null,
            goalTags: thisTrackGoal?.tags || null,
          };
        }

        if (!thisAudioLessonsChunk?.length && !thisAudioChapter?.length) {
          audioNodeType = 'ORPHAN';
          orphanCount += 1;
          return { ...aN, audioNodeType };
        }

        return { ...aN, audioNodeType };
      });

      console.log('lessonCount: ', lessonCount);
      console.log('chunkCount: ', chunkCount);
      console.log('orphanCount: ', orphanCount);
      console.log('total : ', orphanCount + lessonCount + chunkCount);

      setAudioNodeCsvData(processAudioNodes);
    };
    prepareTableData().then(() => setIsLoading(false));
  }, []);

  const navigate = useNavigate();

  const navigateToUnit = (id: string) => {
    navigate('/' + Pages.Unit + '/' + id);
  };
  console.log(navigateToUnit);
  console.log(goals?.length);
  console.log(tags?.length);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <button
        style={{ margin: 20 }}
        onClick={() => setDownloadCSV(true)}
        className='inline-flex self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
      >
        Download Audio Node CSV Data
      </button>
      <button
        style={{ margin: 20 }}
        onClick={() => runJsonDownloadAudioNodes()}
        className='inline-flex self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
      >
        Download Audio Node Json Data
      </button>
      <SearchBar />
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            {isLoading ? (
              <>
                <Skeleton height={50} />
                <Skeleton count={15} className='mt-3' />{' '}
              </>
            ) : (
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Item
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Audio Node Type
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Track Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Track isDefault
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Track Goal
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Unit Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Unit Order
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Lesson Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Lesson Order
                      </th>

                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Chapter Id
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                      >
                        Chapter Order
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {filteredItems.map((audioNode, idx) => {
                      const thisTrackGoal = goals.find((g: GoalType) => g?.id === audioNode?.trackGoal);
                      console.log('thisTrackGoal: ', thisTrackGoal);

                      return (
                        <tr key={audioNode.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500 max-w-[75px] truncate'>{idx + 1}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 bg-gray-50'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500 max-w-[75px] truncate'>{audioNode.id}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500 max-w-[75px] truncate'>{audioNode.audioNodeType}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 bg-gray-50'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{audioNode?.title}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>
                                  {audioNode?.trackId} - {audioNode?.trackTitle}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 bg-gray-50'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>
                                  {audioNode?.isIntroSeries === true ? 'TRUE' : 'FALSE'}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{audioNode?.trackGoal}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 bg-gray-50'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>
                                  {audioNode?.unitId}- {audioNode?.unitTitle}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{audioNode?.unit}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 bg-gray-50'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>
                                  {audioNode?.lessonId} - {audioNode?.lessonTitle}
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{audioNode?.lessonOrder}</div>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 bg-gray-50'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{audioNode?.chapterId}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                            <div className='flex items-center'>
                              <div className='ml-4'>
                                <div className='text-gray-500'>{audioNode?.chapterOrder}</div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {audioNodeCsvData && downloadCSV && (
        <CSVDownload
          data={audioNodeCsvData}
          asyncOnClick
          onClick={(x, done) => {
            console.log('x');
            done(false);
          }}
        />
      )}
    </div>
  );
};

export default AudioNodeList;
