// noinspection ES6PreferShortImport

/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCoaches } from '../../collections/coach';
import { addNewCohort, getCohortById, updateCohort } from '../../collections/cohort';
import Select from 'react-select';
import { ReactSelectOptions } from '../../types/types';
import { getAllCompanies } from '../../collections/company';
import { getIncentivesTemplateById } from '../../collections/incentiveTemplate';
import { getAccountsForCohort, getAccountsForCompany, setCohortForAccounts } from '../../collections/account';
import { Timestamp } from '../../models/dalaccess';
import { getMeetingsForCohort } from '../../collections/cohortMeeting';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TimezoneSelect from 'react-timezone-select';
import { useAppContext } from '../../contexts/appContext';
import ReactDatePicker from 'react-datepicker';
import { addDays, format, isToday } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { emptyCallback } from '../../utility/GeneralUtilities';
import { IncentiveTemplateType } from '../../modeltypes/incentiveTemplate';
import { CohortConfigType, CohortType, CohortTypeNew } from '../../modeltypes/cohort';
import { getAllUsers } from '../../collections/user';
import SearchBar from '../../components/SearchBar';
import { PencilIcon } from '@heroicons/react/solid';
import { DuplicateIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { CompanyType } from '../../modeltypes/company';

const key = 'new-cohort';

const CohortForm = () => {
  type MeetingTableData = {
    id: string;
    name?: string;
    date: Date;
    url?: string;
    location?: string | null;
  };
  const [allUsers, setAllUsers] = useState<ReactSelectOptions[] | null>(null);
  const [companyUsers, setCompanyUsers] = useState<ReactSelectOptions[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<ReactSelectOptions[]>([]);

  const [coaches, setCoaches] = useState<ReactSelectOptions[] | null>(null);
  const [selectedCoaches, setSelectedCoaches] = useState<ReactSelectOptions[]>([]);

  const [companiesData, setCompaniesData] = useState<CompanyType[]>([]);
  const [companies, setCompanies] = useState<ReactSelectOptions[]>([]);
  const [cohort, setCohort] = useState<CohortType | null>(null);
  const [initialUserIds, setInitialUserIds] = useState<string[]>([]);
  const [incentives, setIncentives] = useState<IncentiveTemplateType | null>(null);
  const [meetingTableData, setMeetingTableData] = useState<MeetingTableData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm } = useAppContext();
  const [copyValue, setCopyValue] = useState<string>();

  const filteredItems = React.useMemo(() => {
    const regex = new RegExp(searchTerm || '', 'i');
    if (!meetingTableData?.length) return [];
    return meetingTableData.filter((item) => {
      const matchName = item.name && item.name.match(regex);
      const matchId = item.id && item.id.match(regex);
      return matchName || matchId;
    });
  }, [searchTerm, meetingTableData]);
  const { id } = useParams();
  const navigate = useNavigate();
  type CohortFormType = {
    name: string;
    company: string;
    description: string;
    // incentive: string;
    timezone?: { offset?: number; name: string };
    creationDate: Date;
    endTime: Date | null;
    config?: CohortConfigType | null;
    isTestCohort?: boolean;
    removeFromStatistics?: boolean;
    isObfuscated?: boolean;
    isEliteUserGroup?: boolean;
  };

  const isNew = useMemo(() => id === 'new', [id]);

  const { setBreadcrumbPaths } = useAppContext();

  useEffect(() => {
    setBreadcrumbPaths([
      {
        label: 'Cohorts',
        path: '/cohorts',
      },
      {
        label: id === 'new' ? 'New Cohort' : 'Edit Cohort',
        path: `/cohorts/${id}`,
      },
    ]);
  }, [id, setBreadcrumbPaths]);

  const { values, handleSubmit, handleChange, setValues, errors, dirty, setFieldValue } = useFormik<CohortFormType>({
    initialValues: {
      name: '',
      company: '',
      description: '',
      // incentive: '',
      timezone: undefined,
      creationDate: new Date(),
      endTime: addDays(new Date(), 15),
      isTestCohort: false,
      removeFromStatistics: false,
      isObfuscated: false,
      isEliteUserGroup: false,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is required'),
      company: yup.string().required('Company is required'),
      description: yup.string(),
      // incentive: yup.string(),
      timezone: yup.object(),
      creationDate: yup.date().required(),
      endTime: yup.date().required(),
    }),
    validate: (values) => {
      const errors: any = {};
      const company = companiesData.find((el) => el.id === values.company);
      if (!company?.isScience) {
        if (!values.timezone) {
          errors.timezone = 'Timezone is required!';
        }
      }
      return errors;
    },
    onSubmit: (values: CohortFormType) => {
      const submitData = async (newCohort: CohortTypeNew) => {
        if (cohort) {
          const removedUsers = initialUserIds.filter((id) => !selectedUsers.some((su) => su.value === id));
          const addedUsers = selectedUsers
            .filter((su) => !initialUserIds.some((id) => su.value === id))
            .map((s) => s.value);
          newCohort.id = cohort.id;
          await updateCohort(cohort.id, newCohort);
          await setCohortForAccounts(removedUsers, null);
          await setCohortForAccounts(addedUsers, cohort.id || '');
        } else {
          const newId = await addNewCohort(newCohort);
          await setCohortForAccounts(
            selectedUsers.map((s) => s.value),
            newId,
          );
        }
      };
      const newCohort: CohortTypeNew = {};

      newCohort.name = values.name;
      newCohort.company = values.company;
      newCohort.description = values.description;
      // newCohort.incentives = values.incentive;
      newCohort.timezone = values.timezone;
      newCohort.coach = selectedCoaches.map(({ value }) => value);
      newCohort.creationDate = Timestamp.fromDate(values.creationDate);
      newCohort.endTime = values.endTime ? Timestamp.fromDate(values.endTime) : null;
      newCohort.config = values.config || null;
      newCohort.isTestCohort = values.isTestCohort || false;
      newCohort.removeFromStatistics = values.removeFromStatistics || false;
      newCohort.isObfuscated = values.isObfuscated || false;
      newCohort.isEliteUserGroup = values.isEliteUserGroup || false;

      toast
        .promise(submitData(newCohort), {
          pending: cohort ? `Updating ${newCohort.name}...` : `Adding ${newCohort.name} Cohort...`,
          success: cohort ? `Updated ${newCohort.name}!` : `Added ${newCohort.name} Cohort!`,
          error: "Can't do it now, try again.",
        })
        .then(() => {
          sessionStorage.removeItem(key);
          navigate('/cohorts');
        });
    },
  });

  useEffect(() => {
    if (isNew && dirty) {
      sessionStorage.setItem(
        key,
        JSON.stringify({
          fields: values,
          coaches: selectedCoaches.map((c) => c.value),
          users: selectedUsers.map((u) => u.value),
        }),
      );
    }
  }, [selectedUsers, selectedCoaches, isNew, values, dirty]);

  useEffect(() => {
    const prepareData = async () => {
      if (id !== undefined && !isNew) {
        await getCohortById(id).then(async (v) => {
          await getAccountsForCohort(v?.id || '').then((a) =>
            setInitialUserIds(a.map((acc) => acc.id || '').filter((id) => id !== '')),
          );

          setValues({
            company: v?.company || '',
            name: v?.name || '',
            description: v?.description || '',
            timezone: v?.timezone,
            creationDate: v?.creationDate?.toDate() || new Date(),
            endTime: v?.endTime?.toDate() || null,
            config: v?.config || null,
            isTestCohort: v?.isTestCohort || false,
            isObfuscated: v?.isObfuscated || false,
            removeFromStatistics: v?.removeFromStatistics || false,
            isEliteUserGroup: v?.isEliteUserGroup || false,
          }).then(emptyCallback);

          const tableD: MeetingTableData[] = [];
          await getMeetingsForCohort(id).then((m) => {
            m.forEach((meeting) => {
              tableD.push({
                id: meeting.id,
                date: meeting.date.toDate(),
                name: meeting.title,
                url: meeting.url,
                location: meeting.location,
              });
            });
            setMeetingTableData(tableD);
          });
          if (v?.incentives) {
            await getIncentivesTemplateById(v.incentives).then((incentive) => setIncentives(incentive));
          }
          setCohort(v);
        });
      } else {
        setCohort(null);
      }

      await getAllUsers().then((u) => {
        const opt: ReactSelectOptions[] = [];
        u.forEach((i) =>
          opt.push({
            value: i.id || '',
            label: i.firstName + ' ' + i.lastName,
          }),
        );
        setAllUsers(opt);
      });

      await getAllCoaches().then((coachesArray) => {
        const opt: ReactSelectOptions[] = [];
        coachesArray.forEach((i) =>
          opt.push({
            value: i.id || '',
            label: i.name || '',
          }),
        );
        setCoaches(opt);
      });

      await getAllCompanies().then((c) => {
        setCompaniesData(c);
        const opt: ReactSelectOptions[] = [];
        c.forEach((i) => {
          if (i.companyName) {
            opt.push({
              value: i.id,
              label: i.companyName,
            });
          }
        });
        setCompanies(opt);
      });
    };
    prepareData().then(() => setIsLoading(false));
  }, [id, isNew, setValues]);

  useEffect(() => {
    if (cohort && coaches) {
      setSelectedCoaches(coaches.filter((c) => cohort.coach?.includes(c.value)));
    }
  }, [coaches, cohort]);

  useEffect(() => {
    if (isNew && allUsers && coaches) {
      const formAsString = sessionStorage.getItem(key);
      if (formAsString) {
        const formObject = JSON.parse(formAsString);
        const formData = formObject.fields;
        const _selectedUsers = formObject.users as string[];
        const _selectedCoaches = formObject.coaches as string[];

        const savedForm: CohortFormType = {
          ...formData,
          creationDate: new Date(formData.creationDate),
          endTime: new Date(formData.endTime),
        };

        setSelectedUsers(allUsers.filter((u) => _selectedUsers.includes(u.value)));
        setSelectedCoaches(coaches.filter((u) => _selectedCoaches.includes(u.value)));
        setValues({ ...savedForm });
      }
    }
  }, [coaches, allUsers, isNew, setValues]);

  useEffect(() => {
    const getData = async () => {
      if (cohort === null) return;
      const accs = await getAccountsForCohort(cohort.id || '');
      setSelectedUsers(companyUsers.filter((o) => accs.some((a) => a.id === o.value)));
    };
    getData().then(emptyCallback);
  }, [companyUsers, cohort]);

  useEffect(() => {
    if (allUsers) {
      getAccountsForCompany(values?.company || null).then((a) => {
        setCompanyUsers(allUsers.filter((u) => a.some((item) => item.uid === u.value)));
      });
    }
  }, [values.company, allUsers, coaches]);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={50} />
          <Skeleton count={15} className='mt-3' />{' '}
        </>
      ) : (
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={handleSubmit}>
          <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
            <div>
              <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='company' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Company
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={companies}
                      value={companies.find((c) => c.value === values.company)}
                      onChange={(val) => {
                        if (val?.value !== values.company) {
                          setValues({
                            ...values,
                            company: val?.value || '',
                          });
                          setSelectedUsers([]);
                          setSelectedCoaches([]);
                        }
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                        errors.company ? 'border-red-300' : 'border-gray-300'
                      } rounded-md`}
                    />
                  </div>
                </div>
                <div className='sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='company' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Science Config
                  </label>
                </div>

                <>
                  <div className={'pl-10'}>
                    {companiesData.find((c) => c.id === values.company && c.isScience) ? (
                      <>
                        <div className={'sm:grid sm:grid-cols-3 sm:items-start'}>
                          <label htmlFor='bpm' className='block text-sm font-medium text-gray-700 sm:mt-px'>
                            Show BPM results
                          </label>
                          <input
                            type={'checkbox'}
                            checked={values?.config?.showBpmResults}
                            name={'config.showBpmResults'}
                            id={'config.showBpmResults'}
                            onChange={(e) => setFieldValue('config.showBpmResults', e.target.checked)}
                          />
                        </div>
                      </>
                    ) : null}
                    <div className={'sm:grid sm:grid-cols-3 sm:items-start sm:pt-2'}>
                      <label htmlFor='accelerometer' className='block text-sm font-medium text-gray-700 sm:mt-px '>
                        Show After Session CCE Popup
                      </label>
                      <input
                        type={'checkbox'}
                        checked={values?.config?.showAfterSessionCcePopup}
                        name={'config.showAfterSessionCcePopup'}
                        id={'config.showAfterSessionCcePopup'}
                        onChange={(e) => setFieldValue('config.showAfterSessionCcePopup', e.target.checked)}
                      />
                    </div>

                    <div className={'sm:grid sm:grid-cols-3 sm:items-start sm:pt-2'}>
                      <label htmlFor='accelerometer' className='block text-sm font-medium text-gray-700 sm:mt-px '>
                        Accelerometer
                      </label>
                      <input
                        type={'checkbox'}
                        checked={values?.config?.accelerometerEnabled}
                        name={'config.accelerometerEnabled'}
                        id={'config.accelerometerEnabled'}
                        onChange={(e) => setFieldValue('config.accelerometerEnabled', e.target.checked)}
                      />
                    </div>

                    <div className={'sm:grid sm:grid-cols-3 sm:items-start sm:pt-2'}>
                      <label htmlFor='accelerometer' className='block text-sm font-medium text-gray-700 sm:mt-px '>
                        Gyroscope
                      </label>
                      <input
                        type={'checkbox'}
                        checked={values?.config?.gyroscopeEnabled}
                        name={'config.gyroscopeEnabled'}
                        id={'config.gyroscopeEnabled'}
                        onChange={(e) => setFieldValue('config.gyroscopeEnabled', e.target.checked)}
                      />
                    </div>
                    <div className={'sm:grid sm:grid-cols-3 sm:items-start sm:pt-2'}>
                      <label htmlFor='audioRecording' className='block text-sm font-medium text-gray-700 sm:mt-px'>
                        Audio recording
                      </label>
                      <input
                        type={'checkbox'}
                        checked={values?.config?.audioRecordingEnabled}
                        name={'config.audioRecordingEnabled'}
                        id={'config.audioRecordingEnabled'}
                        onChange={(e) => setFieldValue('config.audioRecordingEnabled', e.target.checked)}
                      />
                    </div>
                  </div>
                </>
                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Name
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='max-w-lg flex rounded-md shadow-sm'>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        autoComplete='name'
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                          errors.name ? 'border-red-300' : 'border-gray-300'
                        } rounded-md`}
                        defaultValue={values.name}
                        onChange={handleChange}
                        placeholder={'What would you like to call this cohort?'}
                      />
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Description
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <textarea
                      id='description'
                      name='description'
                      rows={3}
                      className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                      defaultValue={values.description}
                      onChange={handleChange}
                      placeholder={'What would you like users to know about this cohort?'}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='description' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Duration
                  </label>
                  <div className='mt-1 sm:mt-0 '>
                    <ReactDatePicker
                      selected={values.creationDate}
                      onChange={(dates) => {
                        const [start, end] = dates;
                        if (start) {
                          setValues({
                            ...values,
                            creationDate: start,
                            endTime: end,
                          });
                        }
                      }}
                      startDate={values.creationDate}
                      endDate={values.endTime}
                      selectsRange
                      className={`max-w-lg shadow-sm w-6/12 block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                        errors.endTime || errors.creationDate ? 'border-red-300' : 'border-gray-300'
                      } rounded-md`}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='photo' className='block text-sm font-medium text-gray-700'>
                    Photo
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <div className='flex items-center'>
                      <span className='h-12 w-12 rounded-full overflow-hidden bg-gray-100'>
                        <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                          <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                        </svg>
                      </span>
                      <button
                        type='button'
                        className='ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
                {incentives ? (
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 '>
                      Incentive
                    </label>
                    <div
                      className='mt-1 sm:mt-0 sm:col-span-2 hover:cursor-pointer underline hover:text-indigo-500 '
                      onClick={() => navigate(`/Incentives/${incentives.id}`)}
                    >
                      {incentives.name || `ID:  ${incentives.id}`}
                    </div>
                  </div>
                ) : null}

                {companiesData.find((c) => c.id === values.company && c.isScience) ? null : (
                  <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                    <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                      Coaches
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2'>
                      <Select
                        options={coaches || []}
                        isMulti={true}
                        value={selectedCoaches}
                        onChange={(vals) => {
                          setSelectedCoaches(vals as ReactSelectOptions[]);
                        }}
                        className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                      />
                      <div className='flex flex-row align-center '>
                        <h4 className='self-center text-center'>
                          (If you do not see the coach you are looking for, ensure they are in the coaches List)
                        </h4>
                        <button
                          onClick={() => navigate('/Coaches')}
                          className={`mt-2 mb-2  ml-10 align-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        >
                          Coaches
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='coach' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Users
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <Select
                      options={companyUsers}
                      isMulti={true}
                      value={selectedUsers}
                      onChange={(vals) => {
                        setSelectedUsers(vals as ReactSelectOptions[]);
                      }}
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md`}
                    />
                    <div className='flex flex-row align-center '>
                      <h4 className='self-center text-center'>
                        (If you do not see the user you are looking for, ensure they are assigned to the{' '}
                        {companies.find((c) => c.value === values.company)?.label || ''} company)
                      </h4>
                      <button
                        onClick={() => navigate('/users')}
                        className={`mt-2 mb-2  ml-10 align-center justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        Users
                      </button>
                    </div>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='users' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Timezone
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <TimezoneSelect
                      value={values.timezone?.name || ''}
                      onChange={(timezone) =>
                        setValues({
                          ...values,
                          timezone: {
                            offset: timezone?.offset,
                            name: timezone.value,
                          },
                        })
                      }
                      className={`max-w-lg shadow-sm w-full block focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border ${
                        errors.timezone ? 'border-red-300' : 'border-gray-300'
                      } rounded-md`}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Is test cohort
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type={'checkbox'}
                      checked={values.isTestCohort}
                      onChange={handleChange}
                      name='isTestCohort'
                      id='isTestCohort'
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Is the data obfuscated (i.e. click this to not show the users emails)?
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type={'checkbox'}
                      checked={values.isObfuscated}
                      onChange={handleChange}
                      name='isObfuscated'
                      id='isObfuscated'
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Dont Add this Cohort to Statistics
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type={'checkbox'}
                      checked={values.removeFromStatistics}
                      onChange={handleChange}
                      name='removeFromStatistics'
                      id='removeFromStatistics'
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
                  <label htmlFor='incentives' className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'>
                    Is this an Elite User Group
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2'>
                    <input
                      type={'checkbox'}
                      checked={values.isEliteUserGroup}
                      onChange={handleChange}
                      name='isEliteUserGroup'
                      id='isEliteUserGroup'
                    />
                  </div>
                </div>

                {companiesData.find((c) => c.id === values.company && c.isScience) || id === 'new' ? null : (
                  <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
                    <p className='block text-lg font-medium text-gray-700 sm:mt-px sm:pt-2'>Cohort Meetings</p>
                    <div className='space-y-6 sm:space-y-5'>
                      <SearchBar />
                      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-gray-50'>
                            <tr>
                              <th
                                scope='col'
                                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                              >
                                Id
                              </th>
                              <th
                                scope='col'
                                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                              >
                                Name
                              </th>
                              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                Date
                              </th>
                              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                Url
                              </th>
                              <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                                <span className='sr-only'>Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {filteredItems.length > 0 ? (
                              filteredItems.map((meeting) => (
                                <tr key={meeting.id}>
                                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                    <div className='flex items-center'>
                                      <div className='ml-4'>
                                        <div className='text-gray-500'>{meeting.id}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                    <div className='flex items-center'>
                                      <div className='ml-4'>
                                        <div className='text-gray-500'>{meeting.name}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    <div className='font-medium text-gray-900'>{`${format(
                                      meeting.date,
                                      'hh:mm aaaa, MM/dd',
                                    )} ${isToday(meeting.date) ? 'Today!' : ''}`}</div>
                                  </td>
                                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                                    {meeting.url ? (
                                      <div className='flex items-center'>
                                        <a
                                          className='text-gray-500 cursor-pointer hover:text-indigo-500'
                                          href={meeting.url}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          {meeting.url.slice(0, 20) + '...'}
                                        </a>
                                        <div className='ml-7 flex flex-col items-center'>
                                          <DuplicateIcon
                                            className='cursor-pointer h-7 w-7 hover:stroke-indigo-600'
                                            aria-hidden='true'
                                            onClick={() => {
                                              navigator.clipboard.writeText(meeting?.url || '').then(() => {
                                                setCopyValue(meeting.url);
                                              });
                                            }}
                                          />
                                          <div className='text-gray-900 mt-1'>
                                            {copyValue === meeting.url ? 'Copied' : ''}
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </td>
                                  <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
                                    <div>
                                      <PencilIcon
                                        className='cursor-pointer h-7 w-7 fill-indigo-600 hover:fill-indigo-900'
                                        aria-hidden='true'
                                        onClick={() => navigate('/cohortSessions/' + meeting.id)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className='text-center py-3' colSpan={5}>
                                  No meetings found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='flex flex-col items-center'>
                      <h4 className=''>If you do not see the cohort meetings you are looking for, create one here</h4>
                      <button
                        onClick={() => navigate('/cohortSessions')}
                        className={` mt-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        Cohort Meetings
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={() => navigate('/Cohorts')}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {cohort ? 'Update' : 'Create'}
              </button>
              ;
            </div>
          </div>
        </form>
      )}
      ;
    </>
  );
};
export default CohortForm;
